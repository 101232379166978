export const projectConfigApi = [
    {
        of: 'product',

        Apis: {
            create: 'create-product',
            update: 'update-product',
            list: 'get-products-list',
            delete: 'delete-product',
            fetch: 'fetch-products',

        },
        formType: 'MasterConfigWithProductForm',
        inputFieldsLabels: {
            textField: 'Product',
            autoComplete: {
                label: 'Product Name',
                dropDownType: 'sector',
            }
        },
        tableColumns : [{accessorKey : 'sectorName', header: 'Sector'}, {accessorKey : 'subSectorName', header: 'Sub Sector'}, {accessorKey : 'productName', header: 'Product Name'}],
    },
    {
        of: 'sub-project',

        Apis: {
            create: 'create-sub-project',
            update: 'update-sub-project',
            list: 'get-sub-project-list',
            delete: 'delete-sub-project',
            fetch: 'fetch-sub-project',

        },
        formType: 'MasterConfigWithSubProjectForm',
        inputFieldsLabels: {
            textField: 'Sub Project',
            autoComplete: {
                label: 'Sub Project',
                dropDownType: 'sector',
                // optionKey: 'subSectorName'
            }
        },
        tableColumns : [{accessorKey : 'sectorName', header: 'Sector'}, {accessorKey : 'subSectorName', header: 'Sub Sector'}, {accessorKey : 'subProject', header: 'Sub Project'}],
        accordianColumns: [{accessorKey : 'capacities.[0].name', header: 'Capacity'}, {accessorKey : 'capacities.[0].unit_ID.[0].unitName', header: 'Unit'}]
    },
    {
        of: 'sub-sectors',

        Apis: {
            create: 'create-master-data',
            update: 'update-master-data',
            list: 'get-master-list',
            delete: 'delete-master-data',
            name: 'subSectorName',
            parent_ID: 'sector_ID',
            alias: '',
            model : 'MstSubSector',

        },
        formType: 'MasterConfigWithAutoCompleteForm',
        inputFieldsLabels: {
            textField: 'Sub Sector',
            autoComplete: {
                label: 'Sector',
                dropDownType: 'sector',
                optionKey: 'sectorName'
            }
        },
        tableColumns : [{accessorKey : 'parent.sectorName', header: 'Sector'}, {accessorKey : 'subSectorName', header: 'Sub Sector'}]
    },
    {
        of: 'project-scheme',
        Apis: {
            create: 'create-master-data',
            update: 'update-master-data',
            list: 'get-master-list',
            delete: 'delete-master-data',
            // fetch: 'fetch-project-scheme',
            name: 'scheme',
            parent_ID: '',
            // alias: '',
            model : 'MstScheme', // required (mst_amenity)
        },
        formType: 'MasterConfigWithTextfieldForm',
        inputFieldsLabels: {
            textField: 'Project Scheme',
        },
        tableColumns : [{accessorKey : 'scheme', header: 'Scheme'}]
    },
    {
        of: 'sub-milestone',
        Apis: {
            create: 'create-master-data',
            update: 'update-master-data',
            list: 'get-master-list',
            delete: 'delete-master-data',
            fetch: 'fetch-sub-milestone',
            name: 'sub_milestone',
            parent_ID: 'milestone_ID',
            // alias: '',
            model : 'MstSubMilestones', // required (mst_amenity)
        },
        formType: 'MasterConfigWithAutoCompleteForm',
        inputFieldsLabels: {
            textField: 'Sub Milestone',
            autoComplete: {
                label: 'Milestone',
                dropDownType: 'milestones',
                editKey: 'milestone_ID',
                optionKey: {
                    outerkey : 'milestone',
                    innerKey: 'milestone'
                }
            }
        },
        tableColumns : [{accessorKey : 'parent.milestone', header: 'Milestone Name'}, {accessorKey : 'sub_milestone', header: 'Sub Milestone'}]
    },
    {
        of: 'document-title',
        Apis: {
            create: 'create-master-data',
            update: 'update-master-data',
            list: 'get-master-list',
            delete: 'delete-master-data',
            // fetch: 'fetch-document-title',
            name: 'document_title',
            parent_ID: '',
            alias: '',
            model : 'MstDocumentTitle', // required (mst_amenity)
        },
        formType: 'MasterConfigWithTextfieldForm',
        inputFieldsLabels: {
            textField: 'Document Title',
        },
        tableColumns : [{accessorKey : 'document_title', header: 'Document Title'}]
    },
    {
        of: 'milestone',
        Apis: {
            create: 'create-master-data',
            update: 'update-master-data',
            list: 'get-master-list',
            delete: 'delete-master-data',
            fetch: 'fetch-milestone',
            name: 'milestone',
            // parent_ID: '',
            // alias: '',
            model : 'MstMilestones', // required (mst_amenity)
        },
        formType: 'MasterConfigWithTextfieldForm',
        inputFieldsLabels: {
            textField: 'Milestone',
        },
        // tableColumns : [{accessorKey : { outerKey : 'milestone' , innerKey : 'milestone' }, header: 'Milestone'}]
        tableColumns : [{accessorKey : 'milestone', header: 'Milestone'}]
    },
    {
        of: 'technology',
        Apis: {
            create: 'create-master-data',
            update: 'update-master-data',
            list: 'get-master-list',
            delete: 'delete-master-data',
            name: 'technology',
            // parent_ID: '',
            // alias: '',
            model : 'MstTechnology', // required (mst_amenity)
        },
        formType: 'MasterConfigWithTextfieldForm',
        inputFieldsLabels: {
            textField: 'Technology',
        },
        tableColumns : [{accessorKey : 'technology', header: 'Technology'}]
    },
    // {
    //     of: 'status',
    //     Apis: {
    //         create: 'create-master-data',
    //         update: 'update-master-data',
    //         list: 'get-master-list',
    //         delete: 'delete-master-data',
    //         fetch: 'fetch-status',
    //         name: 'status',
    //         alias: 'alias',
    //         // parent_ID: '',
    //         model : 'MstStatus', // required (mst_amenity)
    //     },
    //     formType: 'MasterConfigMultiTextfieldForm',
    //     inputFieldsLabels: {
    //         textFieldOne: 'Status',
    //         textFieldTwo: 'Alias',
    //     },
    //     tableColumns : [{accessorKey : 'status', header: 'Status'}, {accessorKey : 'alias', header: 'Alias'}]
    // },
    {
        of: 'cost-range',
        Apis: {
            create: 'create-master-data',
            update: 'update-master-data',
            list: 'get-master-list',
            delete: 'delete-master-data',
            // fetch: 'fetch-cost-range',
            name: 'cost_range',
            // alias: '',
            // parent_ID: '',
            model : 'MstCostRange', // required (mst_amenity)
            
        },
        formType: 'MasterConfigWithTextfieldForm',
        inputFieldsLabels: {
            textField: 'Cost Range',
        },
        tableColumns : [{accessorKey : 'cost_range', header: 'Cost Range'}]
    },
    {
        of: 'funding-agency',
        Apis: {
            create: 'create-master-data',
            update: 'update-master-data',
            list: 'get-master-list',
            delete: 'delete-master-data',
            fetch: 'fetch-funding-agency',
            name: 'funding_agency',
            // alias: '',
            // parent_ID: '',
            model : 'MstFundingAgency', // required (mst_amenity)
        },
        formType: 'MasterConfigWithTextfieldForm',
        inputFieldsLabels: {
            textField: 'Funding Agency',
        },
        tableColumns : [{accessorKey : 'funding_agency', header: 'Funding Agency'}]
    },
    {
        of: 'contract-model',
        Apis: {
            create: 'create-master-data',
            update: 'update-master-data',
            list: 'get-master-list',
            delete: 'delete-master-data',
            fetch: 'fetch-contract-model',
            name: 'contract_model',
            // alias: '',
            // parent_ID: '',
            model : 'MstContractModel', // required (mst_amenity)
        },
        formType: 'MasterConfigWithTextfieldForm',
        inputFieldsLabels: {
            textField: 'Contract Model',
        },
        tableColumns : [{accessorKey : 'contract_model', header: 'Contract Model'}]
    },
    {
        of: 'project-type',
        Apis: {
            create: 'create-master-data',
            update: 'update-master-data',
            list: 'get-master-list',
            delete: 'delete-master-data',
            name: 'project_type',
            // alias: 'alias',
            // parent_ID: '',
            model : 'MstProjectType', // required (mst_amenity)
        },
        formType: 'MasterConfigWithTextfieldForm',
        inputFieldsLabels: {
            textField: 'Project Type',
        },
        tableColumns : [{accessorKey : 'project_type', header: 'Project Type'}]
    },
    {
        of: 'project-category',
        Apis: {
            create: 'create-master-data',
            update: 'update-master-data',
            list: 'get-master-list',
            delete: 'delete-master-data',
            name: 'project_category',
            // alias: 'alias',
            // parent_ID: '',
            model : 'MstProjectCategory', // required (mst_amenity)
        },
        formType: 'MasterConfigWithTextfieldForm',
        inputFieldsLabels: {
            textField: 'Project Category',
        },
        tableColumns : [{accessorKey : 'project_category', header: 'Project Category'}]
    },



    {
        of: 'funding-status',
        Apis: {
            create: 'create-master-data',
            update: 'update-master-data',
            list: 'get-master-list',
            delete: 'delete-master-data',
            name: 'funding_status',
            parent_ID: '',
            // alias: '',
            model : 'MstFundingStatus', // required (mst_amenity)
        },
        formType: 'MasterConfigWithTextfieldForm',
        inputFieldsLabels: {
            textField: 'Funding Status',
        },
        tableColumns : [{accessorKey : 'funding_status', header: 'Funding Status'}]
    },
    {
        of: 'milestone-progress',
        Apis: {
            create: 'create-master-data',
            update: 'update-master-data',
            list: 'get-master-list',
            delete: 'delete-master-data',
            name: 'milestone_progress',
            parent_ID: '',
            // alias: '',
            model : 'MstMilestoneProgress', // required (mst_amenity)
        },
        formType: 'MasterConfigWithTextfieldForm',
        inputFieldsLabels: {
            textField: 'Milestone Progress',
        },
        tableColumns : [{accessorKey : 'milestone_progress', header: 'Milestone Progress'}]
    },
    // {
    //     of: 'unit',
    //     Apis: {
    //         create: 'create-master-data',
    //         update: 'update-master-data',
    //         list: 'get-master-list',
    //         delete: 'delete-master-data',
    //         name: 'unit',
    //         parent_ID: 'unit_type_ID',
    //         // alias: '',
    //         model : 'MstUnit', // required (mst_amenity)
    //     },
    //     formType: 'MasterConfigWithAutoCompleteForm',
    //     // inputFieldsLabels: {
    //     //     textField: 'Unit',
    //     //     autoComplete: {
    //     //         label: 'Unit Type',
    //     //         dropDownType: 'unit',
    //     //         optionKey: 'unit'
    //     //     }
    //     // },
    //     inputFieldsLabels: {
    //         textField: 'Unit',
    //         autoComplete: {
    //             label: 'Unit',
    //             dropDownType: 'unit',
    //             optionKey: {
    //                 outerkey : 'unit',
    //                 innerKey: 'unitType'
    //             }
    //         }
    //     },
    //     tableColumns : [{accessorKey : 'parent.unit', header: 'Unit'}, {accessorKey : 'unit', header: 'Unit Type'}]
    // },
    {
        of: 'building-structure',
        Apis: {
            create: 'create-master-data',
            update: 'update-master-data',
            list: 'get-master-list',
            delete: 'delete-master-data',
            name: 'building_structure',
            parent_ID: '',
            // alias: '',
            model : 'MstBuildingStructure', // required (mst_amenity)
        },
        formType: 'MasterConfigWithTextfieldForm',
        inputFieldsLabels: {
            textField: 'Building Structure',
        },
        tableColumns : [{accessorKey : 'building_structure', header: 'Building Structure'}]
    },
    {
        of: 'plant-machinery',
        Apis: {
            create: 'create-master-data',
            update: 'update-master-data',
            list: 'get-master-list',
            delete: 'delete-master-data',
            name: 'plant_machinery',
            parent_ID: '',
            // alias: '',
            model : 'MstPlantMachinery', // required (mst_amenity)
        },
        formType: 'MasterConfigWithTextfieldForm',
        inputFieldsLabels: {
            textField: 'Plant Machinery',
        },
        tableColumns : [{accessorKey : 'plant_machinery', header: 'Plant Machinery'}]
    },
    {
        of: 'land-ownership',
        Apis: {
            create: 'create-master-data',
            update: 'update-master-data',
            list: 'get-master-list',
            delete: 'delete-master-data',
            name: 'land_ownership',
            parent_ID: '',
            // alias: '',
            model : 'MstLandOwnership', // required (mst_amenity)
        },
        formType: 'MasterConfigWithTextfieldForm',
        inputFieldsLabels: {
            textField: 'Land Ownership',
        },
        tableColumns : [{accessorKey : 'land_ownership', header: 'Land Ownership'}]
    },
    
]

export const companyConfigApi = [
    {
        of: 'ownership',
        Apis: {
            create: 'create-master-data',
            update: 'update-master-data',
            list: 'get-master-list',
            delete: 'delete-master-data',
            name: 'ownership',
            // alias: 'alias',
            // parent_ID: '',
            model : 'MstOwnership', // required (mst_amenity)
        },
        formType: 'MasterConfigWithTextfieldForm',
        inputFieldsLabels: {
            textField: 'OwnerShip',
        },
        // tableColumns : [{accessorKey : 'Sr.No', header: 'Sr.No'}, {accessorKey : 'ownership', header: 'OwnerShip'}]
        tableColumns : [{accessorKey : 'ownership', header: 'OwnerShip'}]

    },
    {
        of: 'company-type',
        Apis: {
            create: 'create-master-data',
            update: 'update-master-data',
            list: 'get-master-list',
            delete: 'delete-master-data',
            name: 'company_type',
            // alias: 'alias',
            // parent_ID: '',
            model : 'MstCompanyType', // required (mst_amenity)
        },
        formType: 'MasterConfigWithTextfieldForm',
        inputFieldsLabels: {
            textField: 'Company Type',
        },
        tableColumns : [{accessorKey : 'company_type', header: 'Company Type'}]
    },
    {
        of: 'group-company',
        Apis: {
            create: 'create-master-data',
            update: 'update-master-data',
            list: 'get-master-list',
            delete: 'delete-master-data',
            name: 'groupName',  //name
            // alias: 'alias',
            // parent_ID: '',
            model : 'MstCompanyGroups', // required (mst_amenity)
        },
        formType: 'MasterConfigWithTextfieldForm',
        inputFieldsLabels: {
            textField: 'Group Company',
        },
        tableColumns : [{accessorKey : 'groupName', header: 'Group Company'}]
    },
    {
        of: 'industry',
        Apis: {
            create: 'create-master-data',
            update: 'update-master-data',
            list: 'get-master-list',
            delete: 'delete-master-data',
            name: 'industry',
            // alias: 'alias',
            // parent_ID: '',
            model : 'MstIndustry', // required (mst_amenity)
        },
        formType: 'MasterConfigWithTextfieldForm',
        inputFieldsLabels: {
            textField: 'Industry',
        },
        tableColumns : [{accessorKey : 'industry', header: 'Industry'}]
    },
    {
        of: 'company-category',
        Apis: {
            create: 'create-master-data',
            update: 'update-master-data',
            list: 'get-master-list',
            delete: 'delete-master-data',
            name: 'category',
            // alias: 'alias',
            // parent_ID: '',
            model : 'MstCompanyCategory', // required (mst_amenity)
        },
        formType: 'MasterConfigWithTextfieldForm',
        inputFieldsLabels: {
            textField: 'Company Category',
        },
        tableColumns : [{accessorKey : 'category', header: 'Company Category'}]
    },
    // {
    //     of: 'product-services',
    //     Apis: {
    //         create: 'create-master-data',
    //         update: 'update-master-data',
    //         list: 'get-master-list',
    //         delete: 'delete-master-data',
    //         name: 'company_type',
    //         // alias: 'alias',
    //         // parent_ID: '',
    //         model : 'MstCompanyCategory', // required (mst_amenity)
    //     },
    //     formType: 'MasterConfigWithTextfieldForm',
    //     inputFieldsLabels: {
    //         textField: 'product-services',
    //     },
    // },
    {
        of: 'company-document-title',
        Apis: {
            create: 'create-master-data',
            update: 'update-master-data',
            list: 'get-master-list',
            delete: 'delete-master-data',
            name: 'name',
            // alias: 'alias',
            // parent_ID: '',
            model : 'MstCompanyDocumentTitle', // required (mst_amenity)
        },
        formType: 'MasterConfigWithTextfieldForm',
        inputFieldsLabels: {
            textField: 'Company Document Title',
        },
        tableColumns : [{accessorKey : 'name', header: 'Company Document Title'}]
    },
    {
        of: 'office-type',
        Apis: {
            create: 'create-master-data',
            update: 'update-master-data',
            list: 'get-master-list',
            delete: 'delete-master-data',
            name: 'office_type',
            // alias: 'alias',
            // parent_ID: '',
            model : 'MstOfficeType', // required (mst_amenity)
        },
        formType: 'MasterConfigWithTextfieldForm',
        inputFieldsLabels: {
            textField: 'Office Type',
        },
        tableColumns : [{accessorKey : 'office_type', header: 'Office Type'}]
    },
    // {
    //     of: 'title',
    //     Apis: {
    //         create: 'create-company-type',
    //         update: 'update-company-type',
    //         fetch: 'fetch-company-type',
    //         list: 'get-company-type-list',
    //         delete: 'delete-master-data'
    //     },
    //     formType: 'MasterConfigWithTextfieldForm',
    //     inputFieldsLabels: {
    //         textField: 'title',
    //     },
    // },
    {
        of: 'designation',
        Apis: {
            create: 'create-master-data',
            update: 'update-master-data',
            list: 'get-master-list',
            delete: 'delete-master-data',
            name: 'designation',
            // alias: 'alias',
            // parent_ID: '',
            model : 'MstDesignation', // required (mst_amenity)
        },
        formType: 'MasterConfigWithTextfieldForm',
        inputFieldsLabels: {
            textField: 'designation',
        },
        tableColumns : [{accessorKey : 'designation', header: 'Designation'}]
    },
    {
        of: 'no-of-employees',
        Apis: {
            create: 'create-master-data',
            update: 'update-master-data',
            list: 'get-master-list',
            delete: 'delete-master-data',
            name: 'no_of_employee',
            // alias: 'alias',
            // parent_ID: '',
            model : 'MstNoOfEmployee', // required (mst_amenity)
        },
        formType: 'MasterConfigWithTextfieldForm',
        inputFieldsLabels: {
            textField: 'No Of Employee',
        },
        tableColumns : [{accessorKey : 'no_of_employee', header: 'No Of Employees'}]
    },
    {
        of: 'credit-ratings',
        Apis: {
            create: 'create-master-data',
            update: 'update-master-data',
            list: 'get-master-list',
            delete: 'delete-master-data',
            name: 'credit_ratings',
            // alias: 'alias',
            // parent_ID: '',
            model : 'MstCreditRatings', // required (mst_amenity)
        },
        formType: 'MasterConfigWithTextfieldForm',
        inputFieldsLabels: {
            textField: 'Credit Ratings',
        },
        tableColumns : [{accessorKey : 'credit_ratings', header: 'Credit Ratings'}]
    },




    {
        of: 'company-status',
        Apis: {
            create: 'create-master-data',
            update: 'update-master-data',
            list: 'get-master-list',
            delete: 'delete-master-data',
            name: 'status',
            parent_ID: '',
            // alias: '',
            model : 'MstCompanyStatus', // required (mst_amenity)
        },
        formType: 'MasterConfigWithTextfieldForm',
        inputFieldsLabels: {
            textField: 'Company Status',
        },
        tableColumns : [{accessorKey : 'status', header: 'Company Status'}]
    },  
    {
        of: 'company-listed',
        Apis: {
            create: 'create-master-data',
            update: 'update-master-data',
            list: 'get-master-list',
            delete: 'delete-master-data',
            name: 'listed',
            parent_ID: '',
            // alias: '',
            model : 'MstCompanyListed', // required (mst_amenity)
        },
        formType: 'MasterConfigWithTextfieldForm',
        inputFieldsLabels: {
            textField: 'Company Listed',
        },
        tableColumns : [{accessorKey : 'listed', header: 'Company Listed'}]
    },
    {
        of: 'company-turnover',
        Apis: {
            create: 'create-master-data',
            update: 'update-master-data',
            list: 'get-master-list',
            delete: 'delete-master-data',
            name: 'turn_over',
            parent_ID: '',
            // alias: '',
            model : 'MstCompanyTurnOver', // required (mst_amenity)
        },
        formType: 'MasterConfigWithTextfieldForm',
        inputFieldsLabels: {
            textField: 'Company TurnOver',
        },
        tableColumns : [{accessorKey : 'turn_over', header: 'Company TurnOver'}]
    },
    // {
    //     of: 'company-documents',
    //     Apis: {
    //         create: 'create-master-data',
    //         update: 'update-master-data',
    //         list: 'get-master-list',
    //         delete: 'delete-master-data',
    //         name: 'company_documents',
    //         parent_ID: '',
    //         // alias: '',
    //         model : 'MstCompanyDocuments', // required (mst_amenity)
    //     },
    //     formType: 'MasterConfigWithTextfieldForm',
    //     inputFieldsLabels: {
    //         textField: 'Company Documents',
    //     },
    //     tableColumns : [{accessorKey : 'company_documents', header: 'Company Documents'}]
    // },
    // {
    //     of: 'company-approval-status',
    //     Apis: {
    //         create: 'create-master-data',
    //         update: 'update-master-data',
    //         list: 'get-master-list',
    //         delete: 'delete-master-data',
    //         name: 'status',
    //         parent_ID: '',
    //         // alias: '',
    //         model : 'MstCompanyApprovalStatus', // required (mst_amenity)
    //     },
    //     formType: 'MasterConfigWithTextfieldForm',
    //     inputFieldsLabels: {
    //         textField: 'Company Approval Status',
    //     },
    //     tableColumns : [{accessorKey : 'status', header: 'Company Approval Status'}]
    // },

]

export const masterConfigModules = {
    project: 'project-configuration',
    company: 'company-configuration',
}

export const masterConfigFormTypes = {
    FormWithTextfield: 'MasterConfigWithTextfieldForm',
    FormWithAutoComplete: 'MasterConfigWithAutoCompleteForm',
    FormWithMultiTextfield: 'MasterConfigMultiTextfieldForm',
    MasterConfigWithSubProjectForm: 'MasterConfigWithSubProjectForm',
    MasterConfigWithProductForm: 'MasterConfigWithProductForm'
}