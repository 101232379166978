import * as actionTypes from '../constants/StoreConstants';

export const initialState = {
    tenderDetails: {},
};

const tendersReducer = (state, { type, payload }) => {
    switch (type) {
        case actionTypes?.SET_TENDERSDETAILS:
            return { ...state, tenderDetails: { ...state.tenderDetails, ...payload } };
        default:
            return state;
    }
};

export default tendersReducer;
