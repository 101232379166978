import * as Yup from 'yup';

export const removeAuthUserFromStorage = () => {
  window.localStorage.removeItem('authToken');
};

export const setTokenToStorage = (token) => {
  window.localStorage.setItem('authToken', token);
};

export const defaultSnackBarState = {
  show: false,
  type: 'success',
  message: '',
  vertical: '',
  horizontal: ''
};

export const getTokenFromStorage = () => {
  const token = window.localStorage.getItem('authToken');
  return token;
};

export const validationRegex = {
  pincode: /^[0-9][0-9\- ]{0,10}[0-9]$/,
  email: /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gm,
  mobile: /^(\+\d{1,3}[- ]?)?\d{10}$/,
  direct: /^(\+\d{1,3}[- ]?)?\d{8}$/,
  stdcode: /^(?!\s|.*\s$)\d{1,3}$/,
  extension: /^(?!\s|.*\s$)(?!\s*$).+/,
  latitude: /^[-+]?(([1-8]?\d(\.\d+)?)|90(\.0+)?)$/,
  longitude: /^[-+]?((([1-9]?\d|1[0-7]\d)(\.\d+)?)|180(\.0+)?)$/,
  url: /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi,
  other: /^(?!\s|.*\s$)(?!\s*$).+/,
  description: /^(?!\s*$).+/,
  blankSpace: /^(?!\s*$).+/,
  blankSpacesMessage: 'Input cannot be empty or contain only spaces',
  blankSpace: /^(?!\s*$).+/,
};

// from date must be earlier than to date
export const toFromDateValidationSchema = (
  fromKey = 'fromDate',
  toKey = 'toDate',
  labels = {}
) => {
  const fromLabel = labels[fromKey] || fromKey;
  const toLabel = labels[toKey] || toKey;

  return {
    [fromKey]: Yup.date()
      .nullable()
      .max(Yup.ref(toKey), `${fromLabel} must be earlier than or the same as ${toLabel}`),
    [toKey]: Yup.date()
      .nullable()
      .min(Yup.ref(fromKey), `${toLabel} must be later than or the same as ${fromLabel}`),
  };
};

// if either is filled other is mandatory
export const createDatePairValidation = (fromKey, toKey, errorMessage = 'Both dates are required') => {
  return {
    [fromKey]: Yup.string()
      .nullable()
      .test(`${fromKey}-${toKey}-pair`, errorMessage, function (value) {
        const otherDate = this.parent[toKey];
        if (!value && !otherDate) return true;
        if (value && otherDate) return true;
        return false;
      }),
    [toKey]: Yup.string()
      .nullable()
      .test(`${toKey}-${fromKey}-pair`, errorMessage, function (value) {
        const otherDate = this.parent[fromKey];
        if (!value && !otherDate) return true; // Both empty is valid
        if (value && otherDate) return true; // Both filled is valid
        return false; // One filled and one empty is invalid
      }),
  };
};

// combination of above
export const combinedDateValidationSchema = (
  fromKey = 'fromDate',
  toKey = 'toDate',
  labels = {},
  errorMessage = 'Both dates are required'
) => {
  const fromLabel = labels[fromKey] || fromKey;
  const toLabel = labels[toKey] || toKey;

  return {
    // Validation for the from date (earlier or equal to to date)
    [fromKey]: Yup.date()
      .nullable()
      .max(Yup.ref(toKey), `${fromLabel} must be earlier than or the same as ${toLabel}`)
      .test(`${fromKey}-${toKey}-pair`, errorMessage, function (value) {
        const otherDate = this.parent[toKey];
        if (!value && !otherDate) return true; // Both empty is valid
        if (value && otherDate) return true; // Both filled is valid
        return false; // One filled and one empty is invalid
      }),

    // Validation for the to date (later or equal to from date)
    [toKey]: Yup.date()
      .nullable()
      .min(Yup.ref(fromKey), `${toLabel} must be later than or the same as ${fromLabel}`)
      .test(`${toKey}-${fromKey}-pair`, errorMessage, function (value) {
        const otherDate = this.parent[fromKey];
        if (!value && !otherDate) return true; // Both empty is valid
        if (value && otherDate) return true; // Both filled is valid
        return false; // One filled and one empty is invalid
      }),
  };
};


export const titlesData = [
  { label: 'Dr.' },
  { label: 'Mr.' },
  { label: 'Ms.' },
  { label: 'Prof.' }
];

export const formatAddress = (...args) => {
  const arr = [args];
  return arr.filter((e) => e).join(', ');
};

export const trimPayloadDeepOptimized = (payload) => {
  if (payload === null || typeof payload !== 'object') return payload;

  if (Array.isArray(payload)) {
    for (let i = 0; i < payload.length; i++) {
      payload[i] = trimPayloadDeepOptimized(payload[i]);
    }
    return payload;
  }

  const result = {};
  for (const [key, value] of Object.entries(payload)) {
    result[key] = typeof value === 'string' ? value.trim() : trimPayloadDeepOptimized(value);
  }
  return result;
};


export default function removeBlankSpaces(obj) {
  function trimValues(value) {
    if (typeof value === 'string') {
      return value.trim();
    } else if (Array.isArray(value)) {
      return value.map(trimValues);
    } else if (value !== null && typeof value === 'object') {
      return removeBlankSpaces(value);
    }
    return value;
  }

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      obj[key] = trimValues(obj[key]);
    }
  }
  return obj;
}


// modal style
export const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  width: '85%'
};

// close modal style
export const closeBoxStyle = {
  position: 'absolute',
  top: '-1px',
  left: '-50px',
  bgcolor: 'orange',
  width: 50,
  height: 40,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '20px 0 0 20px',
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.3)'
};

export const coordinatesSheetTemplate = [{ latitude: '19.02', longitude: '72.51' }];

export const CrNumberFormat = (value) => {
  // Add Thousand Separator and limit to 2 decimal digits
  if (typeof value === 'string') {
    return value
      .replace(/[^\d.]/g, '')
      .replace(/\.(?=.*\.)/g, '')
      .replace(/^(\d*\.\d{0,2})\d*$/, '$1')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return value;
  }
};

export const handlePrint = (printRef) => {
  const printContent = printRef.current;
  const printWindow = window.open('', '_blank');
  printWindow.document.write('<html><head><title>Print</title>');
  printWindow.document.write(`
      <style>
          body {
              font-family: Arial, sans-serif;
              margin: 20px;
          }
          .common-view-container {
              max-width: 1400px;
              margin: 0 auto;
              padding: 20px;
              background-color: #fff;
          }
          .main-title {
              font-weight: 500;
              text-align: left;
              background-color: #FEA434;
              color: #fff;
              padding: 12px;
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
          }
          .paper {
              overflow: hidden;
              border: 1px solid #FEA535;
              border-radius: 0px;
              margin-top: 20px;
          }
          .key-title {
              font-weight: 600;
              background-color: #FEE698;
              color: #000;
              padding: 10px;
          }
          table {
              width: 100%;
              border-collapse: collapse;
              margin-top: 10px;
          }
          th, td {
              border: 1px solid #FEA535;
              padding: 8px;
              font-size: 0.9rem;
              color: #000;
          }
          th {
              text-align: left;
              font-weight: bold;
              background-color: #FEE698;
          }
          tr:nth-child(even) {
              background-color: #FFF3CC;
          }
          tr:nth-child(odd) {
              background-color: #FFFFFF;
          }
          a {
              color: #e6732f;
              text-decoration: none;
          }
      </style>
  `);

  printWindow.document.write('</head><body>');
  printWindow.document.write('<div class="common-view-container">');
  printWindow.document.write(printContent.innerHTML);
  printWindow.document.write('</div></body></html>');
  printWindow.document.close();

  printWindow.print();

  setTimeout(() => printWindow.close(), 100);
};