/* eslint-disable react/react-in-jsx-scope */
import GavelIcon from '@mui/icons-material/Gavel';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import SettingsIcon from '@mui/icons-material/Settings';
import BusinessIcon from '@mui/icons-material/Business';
import ArticleIcon from '@mui/icons-material/Article';
import DraftsIcon from '@mui/icons-material/Drafts';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import DangerousIcon from '@mui/icons-material/Dangerous';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import PublishIcon from '@mui/icons-material/Publish';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AssessmentIcon from '@mui/icons-material/Assessment';
import EditNoteIcon from '@mui/icons-material/EditNote';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SchemaIcon from '@mui/icons-material/Schema';
import FlagIcon from '@mui/icons-material/Flag';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import TitleIcon from '@mui/icons-material/Title';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import KeyIcon from '@mui/icons-material/Key';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import GroupsIcon from '@mui/icons-material/Groups';
import FactoryIcon from '@mui/icons-material/Factory';
import CategoryIcon from '@mui/icons-material/Category';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import StarsIcon from '@mui/icons-material/Stars';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ListAltIcon from '@mui/icons-material/ListAlt';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import LandslideIcon from '@mui/icons-material/Landslide';
import InventoryIcon from '@mui/icons-material/Inventory';
// Prefix Constants
export const APP_PREFIX = 'app';
export const APP_ROLE_PREFIX = {
  pt_admin: '/admin'
};

// Shared Inbox Routes
export const inboxRoutes = [
  // { to: 'inbox', label: 'Inbox', redirect: 'inbox' },
  { to: 'draft', label: 'Draft', redirect: 'draft' },
  { to: 'inprocess', label: 'In Process', redirect: 'in_process' },
  { to: 'rejected', label: 'Rejected', redirect: 'rejected' },
  { to: 'pendingapproval', label: 'Pending Approval', redirect: 'pendingapproval' },
  { to: 'publish', label: 'Publish', redirect: 'publish' }
];

export const status = ["Active", "InActive"];

// Project Configuration Routes
export const projectConfigurationRoutes = [
  { to: 'product', label: 'Product', redirect: 'product' },
  { to: 'sub-project', label: 'Sub Project', redirect: 'Sub Project' },
  { to: 'sub-sectors', label: 'Sub Sectors', redirect: 'sub-sectors' },
  { to: 'project-scheme', label: 'Project Scheme', redirect: 'project-scheme' },
  { to: 'project-type', label: 'Project Type', redirect: 'project-type' },
  { to: 'contract-model', label: 'Contract Model', redirect: 'contract-model' },
  // { to: 'project-location', label: 'Project Location', redirect: 'project-location' },
  // { to: 'state', label: 'State', redirect: 'state' },
  // { to: 'district-city', label: 'District/city', redirect: 'district-city' },
  { to: 'milestone', label: 'Milestone', redirect: 'milestone' },
  { to: 'cost-range', label: 'Cost Range', redirect: 'cost-range' },
  { to: 'funding-agency', label: 'Funding Agency', redirect: 'funding-agency' },
  // { to: 'type', label: 'Type', redirect: 'type' },
  { to: 'document-title', label: 'Document Title', redirect: 'document-title' },
  // { to: 'status', label: 'Status', redirect: 'status' },
  { to: 'project-category', label: 'Project Category', redirect: 'project-category' },
  { to: 'technology', label: 'Technology', redirect: 'technology' },
  { to: 'sub-milestone', label: 'Sub Milestone', redirect: 'sub-milestone' },
  { to: 'funding-status', label: 'Funding Status', redirect: 'funding-status' },
  { to: 'milestone-progress', label: 'Milestone Progress', redirect: 'milestone-progress' },
  // { to: 'unit', label: 'Unit', redirect: 'unit' },
  { to: 'building-structure', label: 'Company Documents', redirect: 'building-structure' },
  { to: 'plant-machinery', label: 'Plant Machinery', redirect: 'plant-machinery' },
  { to: 'land-ownership', label: 'Land Ownership', redirect: 'land-ownership' }
];

// Company Configuration Routes
export const companyConfigurationRoutes = [
  { to: 'ownership', label: 'Ownership', redirect: 'ownership' },
  { to: 'company-type', label: 'Company Type', redirect: 'company-type' },
  { to: 'group-company', label: 'Group Company', redirect: 'group-company' },
  { to: 'industry', label: 'Industry', redirect: 'industry' },
  { to: 'company-category', label: 'Company Category', redirect: 'company-category' },
  // { to: 'product-services', label: 'Product & Services', redirect: 'product-services' },
  {
    to: 'company-document-title',
    label: 'Company Document Title',
    redirect: 'company-document-title'
  },
  { to: 'office-type', label: 'Office Type', redirect: 'office-type' },
  // { to: 'Title', label: 'Title', redirect: 'title' },
  { to: 'designation', label: 'Designation', redirect: 'designation' },
  { to: 'no-of-employees', label: 'No. of Employees', redirect: 'no-of-employees' },
  { to: 'credit-ratings', label: 'Credit Ratings', redirect: 'credit-ratings' },
  { to: 'company-status', label: 'Company Status', redirect: 'company-status' },
  { to: 'company-listed', label: 'Company Listed', redirect: 'company-listed' },
  { to: 'company-turnover', label: 'Company TurnOver', redirect: 'company-turnover' }
  // { to: 'company-sub-category', label: 'Sub Category', redirect: 'company-sub-category' },
  // { to: 'company-documents', label: 'Company Documents', redirect: 'company-documents' },
  // {
  //   to: 'company-approval-status',
  //   label: 'Company Approval Status',
  //   redirect: 'company-approval-status'
  // }
];

// Common Menu Items
const commonMenuItems = [
  { to: 'project', label: 'Project', icon: <AccountTreeIcon /> },
  { to: 'tender', label: 'Tender', icon: <GavelIcon /> },
  { to: 'contract', label: 'Contract', icon: <ArticleIcon /> },
  { to: 'company', label: 'Company', icon: <BusinessIcon /> },
  { to: 'manage-user', label: 'User Management', icon: <ManageHistoryIcon /> },
  { to: 'master-configuration', label: 'Master Configuration', icon: <SettingsIcon /> }
];

export const masterConfig = [
  { to: 'project-configuration', label: 'Project Configuration', icon: <AccountTreeIcon /> },
  { to: 'company-configuration', label: 'Company Configuration', icon: <BusinessIcon /> }
];

// Role-based Menus
export const menus = {
  pt_admin: commonMenuItems,
  pt_researcher: commonMenuItems,
  pt_approver: commonMenuItems
};

// Shared SubMenu Items
const commonSubMenus = {
  company: [
    { to: 'Companies', label: 'Companies', icon: <FindInPageIcon /> },
    { to: 'MyCompanies', label: 'My Companies', icon: <AssessmentIcon /> }
    // { to: 'Inbox', label: 'Inbox', icon: <EmailIcon /> }
  ],
  project: [
    { to: 'Projects', label: 'Projects', icon: <AssessmentIcon /> },
    { to: 'MyProjects', label: 'My Projects', icon: <FindInPageIcon /> }
    // { to: 'Inbox', label: 'Inbox', icon: <EmailIcon /> }
  ],
  tender: [
    { to: 'Tender', label: 'Tender', icon: <AssessmentIcon /> },
    { to: 'MyTender', label: 'My Tender', icon: <FindInPageIcon /> }
  ],
  contract: [
    { to: 'Contracts', label: 'Contracts', icon: <AssessmentIcon /> },
    { to: 'MyContracts', label: 'My Contracts', icon: <FindInPageIcon /> }
  ],
  'manage-user': [
    { to: 'User', label: 'User', icon: <AssessmentIcon /> },
    { to: 'Role', label: 'Role', icon: <FindInPageIcon /> }
  ],
  'project-configuration': [
    { to: 'product', label: 'Product', icon: <InventoryIcon /> },
    { to: 'sub-project', label: 'Sub Project', icon: <AssignmentIcon /> },
    { to: 'sub-sectors', label: 'Sub Sectors', icon: <AssessmentIcon /> },
    { to: 'project-scheme', label: 'Project Scheme', icon: <SchemaIcon /> },
    { to: 'project-type', label: 'Project Type', icon: <MenuBookIcon /> },
    { to: 'contract-model', label: 'Contract Model', icon: <EditNoteIcon /> },
    // { to: 'project-location', label: 'Project Location', icon: <AssessmentIcon /> },
    // { to: 'state', label: 'State', icon: <AssessmentIcon /> },
    // { to: 'district-city', label: 'District/city', icon: <AssessmentIcon /> },
    { to: 'milestone', label: 'Milestone', icon: <FlagIcon /> },
    { to: 'cost-range', label: 'Cost Range', icon: <CurrencyRupeeIcon /> },
    { to: 'funding-agency', label: 'Funding Agency', icon: <CorporateFareIcon /> },
    // { to: 'type', label: 'Type', icon: <AssessmentIcon /> },
    { to: 'document-title', label: 'Document Title', icon: <TitleIcon /> },
    // { to: 'status', label: 'Status', icon: <CheckCircleIcon /> },
    { to: 'project-category', label: 'Project Category', icon: <CategoryIcon /> },
    { to: 'technology', label: 'Technology', icon: <SettingsSuggestIcon /> },
    { to: 'sub-milestone', label: 'Sub Milestone', icon: <AssessmentIcon /> },
    { to: 'funding-status', label: 'Funding Status', icon: <CheckCircleIcon /> },
    { to: 'milestone-progress', label: 'Milestone Progress', icon: <RotateRightIcon /> },
    // { to: 'unit', label: 'Unit', icon: <AssessmentIcon /> },
    { to: 'building-structure', label: 'Building Structure', icon: <ApartmentIcon /> },
    { to: 'plant-machinery', label: 'Plant Machinery', icon: <PrecisionManufacturingIcon /> },
    { to: 'land-ownership', label: 'Land Ownership', icon: <LandslideIcon /> }
  ],
  'company-configuration': [
    { to: 'ownership', label: 'Ownership', icon: <KeyIcon /> },
    { to: 'company-type', label: 'Company Type', icon: <BusinessCenterIcon /> },
    { to: 'group-company', label: 'Group Company', icon: <GroupsIcon /> },
    { to: 'industry', label: 'Industry', icon: <FactoryIcon /> },
    { to: 'company-category', label: 'Company Category', icon: <CategoryIcon /> },
    // { to: 'product-services', label: 'Product & Services', icon: <AssessmentIcon /> },
    {
      to: 'company-document-title',
      label: 'Company Document Title',
      icon: <DocumentScannerIcon />
    },
    { to: 'office-type', label: 'Office Type', icon: <CorporateFareIcon /> },
    // { to: 'title', label: 'Title', icon: <AssessmentIcon /> },
    { to: 'designation', label: 'Designation', icon: <AssessmentIcon /> },
    { to: 'no-of-employees', label: 'No. of Employees', icon: <GroupsIcon /> },
    { to: 'credit-ratings', label: 'Credit Ratings', icon: <StarsIcon /> },
    { to: 'company-status', label: 'Company Status', icon: <CheckCircleIcon /> },
    { to: 'company-listed', label: 'Company Listed', icon: <ListAltIcon /> },
    { to: 'company-turnover', label: 'Company TurnOver', icon: <MonetizationOnIcon /> }
    // { to: 'company-sub-category', label: 'Sub Category', icon: <AssessmentIcon /> },

    // { to: 'company-documents', label: 'Company Documents', icon: <AssessmentIcon /> },
    // { to: 'company-approval-status', label: 'Company Approval Status', icon: <AssessmentIcon /> }
  ]
};

export const subMenus = {
  pt_admin: commonSubMenus,
  pt_researcher: commonSubMenus,
  pt_approver: commonSubMenus
};

// Shared SubMenu Icons
const commonSubMenuIcons = {
  company: [
    { to: 'Draft', label: 'Draft', icon: <DraftsIcon /> },
    { to: 'In Process', label: 'In Process', icon: <AccessTimeIcon /> },
    { to: 'Rejected', label: 'Rejected', icon: <DangerousIcon /> },
    { to: 'Pending Approval', label: 'Pending Approval', icon: <PendingActionsIcon /> },
    { to: 'Publish', label: 'Publish', icon: <PublishIcon /> }
  ],
  project: [
    { to: 'Draft', label: 'Draft', icon: <DraftsIcon /> },
    { to: 'Rejected', label: 'Rejected', icon: <DangerousIcon /> },
    { to: 'Pending Approval', label: 'Pending Approval', icon: <PendingActionsIcon /> },
    { to: 'Publish', label: 'Publish', icon: <PublishIcon /> },
    { to: 'New Project Task', label: 'New Project Task', icon: <PublishIcon /> }
  ],
  tender: [
    { to: 'Draft', label: 'Draft', icon: <DraftsIcon /> },
    { to: 'Rejected', label: 'Rejected', icon: <DangerousIcon /> },
    { to: 'Pending Approval', label: 'Pending Approval', icon: <PendingActionsIcon /> },
    { to: 'Publish', label: 'Publish', icon: <PublishIcon /> }
  ]
};

export const subNavStatusListApi = {
  company: 'get-status-list',
  project: 'get-project-status-list',
  tender: 'get-tender-status-list'
};

export const subMenusIcon = {
  pt_admin: commonSubMenuIcons,
  pt_researcher: commonSubMenuIcons,
  pt_approver: commonSubMenuIcons
};
