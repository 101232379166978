import * as actionTypes from '../constants/StoreConstants';

export const initialState = {
  projectDetails: {},
  projectHistory: [],
  projectCost: {},
  projectOfficial: {},
  sectorDetails: {},
  subSectorDetails: {}
};

const projectReducer = (state, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_PROJECTDETAILS:
      return { ...state, projectDetails: { ...state.projectDetails, ...payload } };
    case actionTypes.SET_PROJECTHISTORY:
      return { ...state, projectHistory: [...state.projectHistory, ...payload] };
    case actionTypes.SET_PROJECTCOST:
      return { ...state, projectCost: { ...state.projectCost, ...payload } };
    case actionTypes.SET_PROJECTOFFICIAL:
      return { ...state, projectOfficial: { ...state.projectOfficial, ...payload } };
    case actionTypes.SET_SECTORDETAILS:
      return { ...state, sectorDetails: { ...state.sectorDetails, ...payload } };
    case actionTypes.SET_SUBSECTORTDETAILS:
      return { ...state, subSectorDetails: { ...state.subSectorDetails, ...payload } };
    default:
      return state;
  }
};

export default projectReducer;
