import { createContext, useContext, useReducer } from 'react';

import tendersReducer, { initialState } from '../../library/common/reducers/tendersContextReducer';

const TendersContext = createContext();

export const useTendersContext = () => useContext(TendersContext);

const TendersProvider = ({ children }) => {
    const useTendersContextState = useReducer(tendersReducer, initialState);

    return (
        <TendersContext.Provider value={[...useTendersContextState]}>
            {children}
        </TendersContext.Provider>
    );
};

export default TendersProvider;