import { Box, CircularProgress, createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
  palette: {
    ochre: {
      main: '#E3D026',
      light: '#FFA500', // Updated to orange
      dark: '#A29415',
      contrastText: '#242105',
    },
  },
});

const Loader = ({ size = 100 }) => {
  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          zIndex: 10,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ThemeProvider theme={theme}>
          <CircularProgress 
            sx={{ color: theme.palette.ochre.light }} // Orange color applied
            size={size} 
          />
        </ThemeProvider>
      </Box>
    </>
  );
};

export default Loader;
