import React, { lazy } from 'react';
import {
  SubNavMenuCompany,
  SubNavMenuProjects,
  SubNavMenuContracts,
  SubNavMenuTenders,
  SubNavMenuUser,
  SubNavMenuProjectConfig,
  SubNavMenuCompanyConfig
} from './subNav';
import { Outlet } from 'react-router-dom';
const Home = lazy(() => import('../../screens/Main/Home/Home'));

const ArrayForNav = [
  'project',
  'tender',
  'contract',
  'company',
  'manage-user',
  'project-configuration',
  'company-configuration'
];

const getSubNavMenu = (path) => {
  switch (path) {
    case 'project':
      return SubNavMenuProjects;
    case 'tender':
      return SubNavMenuTenders;
    case 'contract':
      return SubNavMenuContracts;
    case 'company':
      return SubNavMenuCompany;
    case 'manage-user':
      return SubNavMenuUser;
    case 'project-configuration':
      return SubNavMenuProjectConfig;
    case 'company-configuration':
      return SubNavMenuCompanyConfig;
    default:
      return [];
  }
};

const superAdminNav = ArrayForNav?.map((path) => {
  return {
    path: path,
    element: <Outlet />,
    children: getSubNavMenu(path)
  };
});

export default superAdminNav;
