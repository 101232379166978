import React, { Suspense, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Router from './main/routes';
import { getTokenFromStorage } from './utils/common';
import { useSelector, useDispatch } from 'react-redux';
import { getRequest } from './helpers/httpHelper';
import * as actionTypes from './library/common/constants/StoreConstants';
import Loader from './screens/Loader';

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const token = getTokenFromStorage();

  if (!token && pathname !== '/') {
    window.location.href = '/';
  }

  if (token && pathname === '/') {
    window.location.href = '/app/company/companies';
  }

  const checkUserSignAndRedirect = () => {
    getRequest('get-details-by-token', function (res) {
      if (res?.data) {
        dispatch({ type: actionTypes.SET_AUTH, payload: res?.data?.data });
        if (isLoggedIn && (pathname === '/' || pathname === '/auth/login')) {
          navigate('/app/company/companies');
        }
      }
    });
  };

  useEffect(() => {
    if (!token) {
      return;
    }
    checkUserSignAndRedirect();
  }, [isLoggedIn]);

  // useEffect(() => {
  //   if((pathname === '/' || pathname === '/auth/login') && isLoggedIn) {
  //     redirectOnBaseUrl();
  //   }
  //   if((pathname !== '/' || pathname !== '/auth/login') < 0) {
  //     redirectOnBaseUrl();
  //   }
  // }, [pathname])

  return (
    <Suspense fallback={<Loader/>}>
      <Router />
    </Suspense>
  );
}

export default App;
